<template>
  <b-card class="login-body mb-5">
    <h3 class="text-center mt-4 mb-4">Aktivera ditt konto</h3>
    
      
      <form class="login-form" v-if="showForm">
        <b-alert
          :show="showWarning"
          variant="danger"
          dismissible
          @dismissed="showWarning = false"
          fade
          >{{$t('ACCOUNT.INVALID_EMAIL_PASSWORD')}}</b-alert
        >

        <b-form-group id="input-group-companyname" label="Företagsnamn" label-for="input-companyname">
          <b-form-input id="input-companyname" v-model="company_name" type="text" disabled></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-firstname" label="Förnamn" label-for="input-firstname">
          <b-form-input id="input-firstname" v-model="form.firstname" type="text"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-lastname" label="Efternamn" label-for="input-lastname">
          <b-form-input id="input-lastname" v-model="form.lastname" type="text"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-personnr">
          <tip-label class="mb-2" for="input-personnr" asterix>Personnummer</tip-label>
          
            <b-form-input
              id="input-personnr"
              v-model="form.in_personnr"
              placeholder="YYYYMMDD-XXXX"
              type="text"
            >
            </b-form-input>
            <b-form-invalid-feedback>Ange giltigt personnummer</b-form-invalid-feedback>
          
        </b-form-group>

        <b-form-group id="input-group-email" label="E-post" label-for="input-email">
          <b-form-input id="input-email" v-model="form.email" type="text" disabled></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-phone" label="Telefon" label-for="input-phone">
          <b-form-input id="input-phone" v-model="form.phone" type="text"></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-password" :label="$t('MEMBER.PASSWORD')" label-for="input-password">
          <b-form-input id="input-password" v-model="form.password" type="password"></b-form-input>
        </b-form-group>

        <div class="d-flex align-items-center justify-content-end mb-8">
          <!-- <b-button class="btn-login" variant="primary" @click="onSubmit">Aktivera konto</b-button> -->
          <b-button class="btn-login" variant="primary" @click="onSubmit">Aktivera konto</b-button>
        </div>
      </form>
      <div class="warning-message" v-else>
        <b-alert variant="danger" fade show>
          <div class="d-flex align-items-center justify-content-center p-4">
            <div>
              <fa-icon :icon="['far', 'question-circle']" size="3x"></fa-icon>
            </div>
            <div class="ml-4">Aktiveringslänken är antingen felaktig eller för gammal.</div>
          </div>
        </b-alert>
      </div>

  </b-card>
</template>

<style lang="scss" scoped>
.login-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
  .warning-message {
    padding: 30px 20px;
  }
}
</style>

<script>
import { mapState } from 'vuex';
import validate_pers_number from '@/core/services/personnr';
import axios from 'axios';
import TipLabel from '@/view/components/TipLabel.vue';
import store from '@/core/services/store';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  props: ['redirect'],
  mixins: [ toasts ],
  name: 'activate',
  components: { TipLabel },
  created() {
    
  },

  data() {
    return {
      // Remove this dummy login info
      company_id: null,
      company_name: '',
      form: {
        user_id: 0,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        in_personnr: '',
        password: '',
        token: '',
      },
      showWarning: false,
      showForm: false,
    };
  },

  mounted() {
    var user_id = this.$route.params.user_id;
    var token = this.$route.params.token;
    this.getClientByToken(token);
  },

  methods: {

    
    getClientByToken(token) {
      axios
        .get(`/user/bytoken?invite_token=${token}`)
        .then(res => {
          this.form.email = res.data.user.email;
          this.form.firstname = res.data.user.firstname;
          this.form.lastname = res.data.user.lastname;
          this.form.in_personnr = res.data.user.in_personnr;
          this.form.token = res.data.user.invite_token;
          this.company_name = res.data.company.name;
          this.showForm = true;

        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta användardata');
        });
    },
    async onSubmit() {

      const user_id = parseInt(this.$route.params.user_id);
      const token = this.form.token;
      const firstname = this.form.firstname;
      const lastname = this.form.lastname;
      const in_personnr = this.form.in_personnr;
      const email = this.form.email;
      const phone = this.form.phone;
      const password = this.form.password;

      if (!password || password.length < 6) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('VALIDATIONS.ENTER_PASSWORD'));
        return;
      }

      // show loading
      const loader = this.$loading.show();

      const res = await axios.post('/user/activate', { user_id, token, password, firstname, lastname, in_personnr, email, phone });
      if (res.status === 200) {
        this.$router.push('/ml-main-login');
        
      } else if (res.status === 403) {
        // un authorized
        this.$func.showTextMessage('Unauthorized', 'Please check your credentials', 'info');
        this.showWarning = true;
      } else if (res.status === 500) {
        this.$func.showTextMessage('Unauthorized', 'Please check your credentials', 'info');
        this.showWarning = true;
      }
      loader & loader.hide();
    },
    
  },
  computed: {
    
  }
};
</script>
